import AuthenticatedEndpoint from '../helpers/api';

function submitStats(payload) {
  return AuthenticatedEndpoint.post(null, payload, {
    params: {
      op: 'finalstats',
    },
  })
    .then((res) => {
      let data = res.data;

      // Check if the response data is a string
      if (typeof data === 'string') {
        const parts = data.split('<br>');
        if (parts.length > 1) {
          const jsonPart = parts[1].trim();
          try {
            data = JSON.parse(jsonPart);
            return data.result.leaguetableurl; // Return the desired data
          } catch (error) {
            throw new Error('Failed to parse response from server.');
          }
        } else {
          throw new Error('Unexpected response format.');
        }
      }

      // Return null if data is not as expected
      return null;
    })
    .catch((error) => {
      // Check if the error contains a response with data
      if (error.response?.data) {
        throw error.response.data; // Re-throw the error object for further handling
      }

      // For other errors (e.g., network errors), throw a generic message
      throw new Error(error.message || 'An unknown error occurred.');
    });
}

export const statsServices = {
  submitStats,
};
