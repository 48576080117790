import React from 'react';
import { Button, Form, Icon, Grid, List, Modal } from 'semantic-ui-react';
import Tournament from './Tournament';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { tournamentsActions } from '../actions/tournaments.actions';
import { venuesActions } from '../actions/venues.actions';
import Venues from './Venues';

class TournamentSelector extends React.Component {
  state = {
    modalOpen: true,
    isConfirmModalOpen: false,
    errorMessage: '',
    date: moment().format('DD-MM-YYYY'),
    venue: null,
    name: null,
    venueSearchValue: null,
  };

  handleClose = () => this.setState({ modalOpen: false });

  toggleConfirmModal = (message = '') => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
      errorMessage: message,
    });
  };

  handleCreateTournament = async () => {
    try {
      const response = await this.props.createTournament(
        this.state.name,
        this.state.date,
        this.state.venue
      );

      if (response) {
        this.handleClose();
      } else {
        this.toggleConfirmModal(response.error || 'An unknown error occurred.');
      }
    } catch (error) {
      this.toggleConfirmModal(error.error || 'An unknown error occurred.');
    }
  };

  renderTournamentList() {
    return this.props.tournaments.map((tournament) => {
      const name = Object.keys(tournament)[0];
      const id = Object.values(tournament)[0];
      return (
        <Tournament name={name} id={id} key={id} onClose={this.handleClose} />
      );
    });
  }

  render(props) {
    return (
      <>
        <Modal
          open={this.props.activeTournamentId === null}
          dimmer="blurring"
          className="players-modal modal-dimmer-bg-color"
        >
          <Modal.Header className="players-modal-header">
            Select a Tournament
          </Modal.Header>
          <Modal.Content className="players-modal-content modal-bg-content-container">
            <Modal.Description>
              <Grid columns="equal">
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <List className="list-of-tournament">
                      {this.renderTournamentList()}
                    </List>
                  </Grid.Column>
                  <Grid.Column>
                    <Form>
                      <Form.Field>
                        <label className="players-modal-secodary-headers">
                          Name
                        </label>
                        <input
                          placeholder="Name"
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                        />
                      </Form.Field>
                      <Form.Field>
                        <label className="players-modal-secodary-headers">
                          Date
                        </label>
                        <DateInput
                          clearable
                          clearIcon={<Icon name="remove" color="black" />}
                          name="date"
                          value={this.state.date}
                          onChange={(a, { name, value }) =>
                            this.setState({ date: value })
                          }
                        />
                      </Form.Field>
                      <Form.Field>
                        <label className="players-modal-secodary-headers">
                          Venue
                        </label>
                        <Venues
                          onChange={(a, { name, value }) =>
                            this.setState({ venue: value })
                          }
                        />
                      </Form.Field>
                      <div id="create-tournament-button">
                        <Button
                          type="submit"
                          className="ui button rounds-action-buttons"
                          onClick={this.handleCreateTournament}
                        >
                          Create Tournament
                        </Button>
                        <Button
                          type="submit"
                          className="ui button rounds-action-buttons"
                          onClick={() => {
                            this.props.logout();
                            this.handleClose();
                          }}
                        >
                          Logout
                        </Button>
                      </div>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        {/* Error Modal */}
        <Modal
          open={this.state.isConfirmModalOpen}
          size="small"
          style={{
            width: '350px',
            maxWidth: '80%',
            marginTop: '20px',
          }}
          className="delete-confirm-popup"
          centered
        >
          <Modal.Content>
            <p>{this.state.errorMessage}</p>
          </Modal.Content>
          <Modal.Actions>
            <div className="error-modal-button-container">
              <Button
                onClick={() => this.toggleConfirmModal('')}
                className="rounds-action-buttons"
                style={{ marginBottom: '15px' }}
                positive
              >
                OK
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tournaments: state.game.tournaments,
    venues: state.game.venues,
    activeTournamentId: state.game.activeTournamentId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTournament: tournamentsActions.createTournament,
      getTournamentDetails: tournamentsActions.getTournamentDetails,
      createVenue: venuesActions.createVenue,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TournamentSelector);
