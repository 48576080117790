import AuthenticatedEndpoint from '../helpers/api';

function createTournament(tournamentname, tournamentdate, venueId) {
  return AuthenticatedEndpoint.post(
    null,
    {
      tournamentname: tournamentname,
      tournamentdate: tournamentdate,
      tournamentvenueid: venueId,
    },
    {
      params: {
        op: `createtournament`,
      },
    }
  )
    .then((res) => {
      return res.data.result; // Return the result on success
    })
    .catch((error) => {
      // Extract and handle the error object
      if (error.response?.data) {
        throw error.response.data; // Re-throw the error object to propagate it
      }
      // Handle other types of errors (e.g., network issues)
      throw new Error(error.message || 'An unknown error occurred');
    });
}

function getTournaments() {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: `getnewtournaments`,
    },
  }).then((res) => {
    return res.data.result;
  });
}

function getTournamentDetails(tournamentId) {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: `gettournamentdetails`,
      tournamentid: tournamentId,
    },
  }).then((res) => {
    return res.data.result;
  });
}

function addPlayerToTournament(
  playerId,
  tournamentId,
  buyin_cash,
  buyin_chips
) {
  const data = {
    playerid: playerId,
    tournamentid: tournamentId,
    buyin_cash: buyin_cash,
    buyin_chips: buyin_chips,
  };

  return AuthenticatedEndpoint.post(null, data, {
    params: {
      op: `tournamentplayeradd`,
    },
  }).then((res) => {
    return res.data.result;
  });
}

function removePlayerFromTournament(playerId, tournamentId) {
  const data = { playerid: playerId, tournamentid: tournamentId };

  return AuthenticatedEndpoint.post(null, data, {
    params: {
      op: `tournamentplayerdelete`,
    },
  }).then((res) => {
    return res.data;
  });
}

export const tournamentsServices = {
  createTournament,
  getTournaments,
  getTournamentDetails,
  addPlayerToTournament,
  removePlayerFromTournament,
};
