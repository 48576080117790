import axios from 'axios';
import store from '../store';
import { userActions } from '../actions/user.actions';

const AuthenticatedEndpoint = axios.create({
  baseURL: `https://www.travispokertimer.com/pokeradmin/timer-api.php`,
});

// Request interceptor
AuthenticatedEndpoint.interceptors.request.use(
  (config) => {
    config.headers['authorization'] = 'Bearer ' + store.getState().user.jwt;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Response interceptor
AuthenticatedEndpoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle 401 Unauthorized
    if (error.response?.status === 401) {
      store.dispatch(userActions.logout());
    }

    // Reject the error to propagate it to the calling code
    return Promise.reject(error);
  }
);

export default AuthenticatedEndpoint;
